import { useCallback, useContext } from 'react';
import { useAppSelector } from './store';
import { Environment, WorkspaceMemberRole } from '../generated/types';
import { SessionUserState } from '../store/slices/session';
import { EnvironmentContext } from '../components/EnvironmentProvider';

export enum FeatureEnum {
  DataManagement = 'DataManagement',
  ChatConfiguration = 'ChatConfiguration',
  SurveyLinkForPeek = 'SurveyLinkForPeek',
  CommonQuestions = 'CommonQuestions',
  Debugging = 'Debugging',
  SuperPower = 'SuperPower',
  AcuityMD = 'AcuityMD',
  CreateAlert = 'CreateAlert',
  EmailBodyHtmlInput = 'EmailBodyHtmlInput',
  Recurrency = 'Recurrency',
  ResponseBias = 'ResponseBias',
  Topics = 'Topics',
  Queries = 'Queries',
  Stacks = 'Stacks',
  CreateWorkspace = 'CreateWorkspace',
}

export enum EnvironmentName {
  TEST = 'Test',
  PEEK_PRO_STAGING = 'PeekProStaging',
  PEEK_PRO_PROD = 'PeekProProd',
  ACUITYMD_DEMO = 'AcuityDemo',
  ACUITYMD_STAGING = 'AcuityStaging',
  RECURRENCY_DEMO = 'RecurrencyDemo',
}

export enum DeploymentLevel {
  DEV = 'development', // process.env.NODE_ENV === 'development'
  TEST = 'test', // process.env.ENV === 'test'
  STAGING = 'staging', // process.env.ENV === 'staging'
  PROD = 'prod', // process.env.ENV === 'prod'
}

// This list is used to disable features for specific environments
export const ENVIRONMENTS: Record<EnvironmentName, string> = {
  [EnvironmentName.TEST]:
    '####################################################', // this test environment is for dev purposes only. Please replace it with your local environment ID.
  [EnvironmentName.PEEK_PRO_STAGING]:
    'MjpiMThjYTQ4Ny1iYzRiLTRhZTAtYTc5NS0yODVjODAyNWIyZjg=',
  [EnvironmentName.PEEK_PRO_PROD]:
    'Mjo1MDE2N2RiZC00YWIzLTQwMjQtOGRhZS0yZTQxNTEyNDUyMGI=',
  [EnvironmentName.ACUITYMD_DEMO]:
    'Mjo4MDg5OGEyZC1iNjNhLTRiNmItYjgzYS04YTczZDBkY2UyNmE=',
  [EnvironmentName.ACUITYMD_STAGING]:
    'MjozNWVhY2Q0NC04YzRiLTRlM2YtYTlhMS0xYzQyNDBiNWU4MWE=',
  [EnvironmentName.RECURRENCY_DEMO]:
    'MjoxMDhmY2EyMi01ZmRjLTRjMTItYWQ4ZC0wNDIxMGFhOWVjMjg=',
};

type Condition =
  | boolean
  | {
      operator: 'AND' | 'OR';
      environmentIds?: string[];
      workspaceUserRoles?: WorkspaceMemberRole[];
      emailRegex?: string;
      deploymentLevels?: DeploymentLevel[];
      userEmails?: string[];
    };

type FeatureFlags = {
  [key in FeatureEnum]: {
    enabled?: Condition;
    disabled?: Condition;
  };
};

// full list of feature flags
const featureFlags: FeatureFlags = {
  [FeatureEnum.DataManagement]: {
    enabled: {
      operator: 'OR',
      emailRegex: '.*@madeinventive.com',
    },
  },
  [FeatureEnum.ChatConfiguration]: {
    enabled: {
      operator: 'OR',
      emailRegex: '.*@madeinventive.com',
    },
  },
  [FeatureEnum.SurveyLinkForPeek]: {
    enabled: {
      operator: 'OR',
      environmentIds: [
        ENVIRONMENTS['Test'],
        ENVIRONMENTS['PeekProStaging'],
        ENVIRONMENTS['PeekProProd'],
      ],
    },
  },
  [FeatureEnum.CommonQuestions]: {
    enabled: {
      operator: 'OR',
      environmentIds: [
        ENVIRONMENTS['Test'],
        ENVIRONMENTS['PeekProStaging'],
        ENVIRONMENTS['PeekProProd'],
        ENVIRONMENTS['AcuityDemo'],
        ENVIRONMENTS['AcuityStaging'],
        ENVIRONMENTS['RecurrencyDemo'],
      ],
      emailRegex: '.*@madeinventive.com',
    },
  },
  [FeatureEnum.AcuityMD]: {
    enabled: {
      operator: 'OR',
      environmentIds: [
        ENVIRONMENTS['Test'],
        ENVIRONMENTS['AcuityDemo'],
        ENVIRONMENTS['AcuityStaging'],
      ],
    },
  },
  [FeatureEnum.Debugging]: {
    enabled: {
      operator: 'OR',
      emailRegex: '.*@madeinventive.com',
    },
  },
  [FeatureEnum.SuperPower]: {
    enabled: {
      operator: 'OR',
      userEmails: [
        'kate+environment@madeinventive.com',
        'kate+peek@madeinventive.com',
        'kavita+peek@madeinventive.com',
        'gary+peek@madeinventive.com',
        'erik+peek@madeinventive.com',
        'charles.wickman+AIChatAdmin@peek.com',
        'clarence@madeinventive.com',
        'clarence+environment@madeinventive.com',
        'clarence+acuitymd@madeinventive.com',
      ],
    },
  },
  [FeatureEnum.CreateAlert]: {
    enabled: true,
  },
  [FeatureEnum.EmailBodyHtmlInput]: {
    enabled: {
      operator: 'OR',
      emailRegex: '.*@madeinventive.com',
    },
  },
  [FeatureEnum.Recurrency]: {
    enabled: {
      operator: 'OR',
      environmentIds: [ENVIRONMENTS['RecurrencyDemo']],
    },
  },
  [FeatureEnum.ResponseBias]: {
    disabled: true,
  },
  [FeatureEnum.Topics]: {
    enabled: true,
  },
  [FeatureEnum.Queries]: {
    enabled: true,
  },
  [FeatureEnum.Stacks]: {
    enabled: {
      operator: 'OR',
      emailRegex: '.*@madeinventive.com',
    },
  },
  [FeatureEnum.CreateWorkspace]: {
    enabled: {
      operator: 'OR',
      emailRegex: '.*@madeinventive.com',
    },
  },
};

const featureFlagOverridesJson = JSON.parse(
  process.env.NEXT_PUBLIC_FEATURE_FLAG_OVERRIDES ?? '{}',
);

const evaluateCondition = (
  condition: Condition,
  userSession: SessionUserState,
  environment: Environment | undefined,
) => {
  if (typeof condition === 'boolean') {
    return condition;
  }

  const {
    operator,
    environmentIds,
    workspaceUserRoles,
    emailRegex,
    deploymentLevels,
    userEmails,
  } = condition;

  const conditions = [];

  if (environmentIds) {
    conditions.push(environmentIds.includes(environment?.id ?? ''));
  }

  if (workspaceUserRoles) {
    conditions.push(
      workspaceUserRoles.includes(
        userSession.workspaceRole as WorkspaceMemberRole,
      ),
    );
  }

  if (deploymentLevels) {
    // if the env is development, check NODE_ENV
    if (process.env.NODE_ENV === 'development') {
      conditions.push(
        deploymentLevels.includes(process.env.NODE_ENV as DeploymentLevel),
      );
    } else {
      // if not, check ENV
      conditions.push(
        deploymentLevels.includes(
          process.env.NEXT_PUBLIC_ENV as DeploymentLevel,
        ),
      );
    }
  }

  if (emailRegex) {
    conditions.push(new RegExp(emailRegex, 'i').test(userSession.email));
  }

  if (userEmails) {
    conditions.push(userEmails.includes(userSession.email));
  }

  return operator === 'OR'
    ? conditions.some(Boolean)
    : conditions.every(Boolean);
};

export const useFeatureFlags = () => {
  const userSession = useAppSelector((store) => store.session.value);
  const { environment } = useContext(EnvironmentContext);

  const isFeatureEnabled = useCallback(
    (feature: FeatureEnum): boolean => {
      // In development mode, evaluate with env overrides first
      if (process.env.NODE_ENV === 'development') {
        const override = featureFlagOverridesJson[feature];
        // if override is not defined, continue with normal evaluation
        if (typeof override !== 'undefined') {
          return override;
        }
      }

      const disabled = featureFlags[feature]?.disabled;
      const enabled = featureFlags[feature]?.enabled;

      if (typeof disabled !== 'undefined') {
        return !evaluateCondition(disabled, userSession, environment);
      }

      if (typeof enabled !== 'undefined') {
        return evaluateCondition(enabled, userSession, environment);
      }

      throw new Error('Invalid feature flag');
    },
    [userSession, environment],
  );

  return {
    isFeatureEnabled,
  };
};
